import React, { Component } from 'react';
import './BettercapModulesHelp.css';
import companyLogo from './../../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class BettercapModulesHelp extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapModulesHelp" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap Modules Help</b>
                                </p>
                                
                                
                                <p className="c9">
                                    <span className="c4">
                                        
                                    </span>
                                </p>
                                
                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                
                                    </span>
                                </p>
                                
                                <p className="c5"><span className="c0"></span></p>
                                
                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                
                                    </span>
                                </p>
                                
                                <p className="c5"><span className="c0"></span></p>
                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}></span>
                                    <span style={{ fontSize: '17pt' }}></span>
                                    <span style={{ fontSize: '17pt' }}></span>
                                </p>
                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 7
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
