import React from 'react';
import './shellterminal.css';
import { Consumer } from './../../../../../Context';
export default function ShellTerminal(props) {

    return (
        <div className='shell-terminal'>
            <main id="container">
                <div id="terminal">

                    <section id="terminal__bar">
                        <div id="bar__buttons">
                            <button className="bar__button" id="bar__button--exit">&#10005;</button>
                            <button className="bar__button">&#9472;</button>
                            <button className="bar__button">&#9723;</button>
                        </div>
                        <p id="bar__user">webschool26@ubuntu: ~</p>
                    </section>

                    <section id="terminal__body">
                        <div id="terminal__prompt">
                            <span id="terminal__prompt--user">{props.user}</span>
                            <span id="terminal__prompt--location">{props.location}</span>
                            <span id="terminal__prompt--bling">$</span>
                            <span id="terminal__prompt--cursor">{props.cursor}</span>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )

}
