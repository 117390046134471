import React, { Component } from 'react'
import './Quiz.css'
import companyLogo from './../../assets/Images/regex.png';
import { Consumer } from "../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import Questionnaire from '../Questionnaire/Questionnaire';
import Clock from '../Clock/Clock';
import { v4 as uuidv4 } from "uuid"

export default class Quiz extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let nextsubTopicIndex = parseInt(c.subTopicIndex);
                        let theSubjectsAndSubTopicsIndex = c.subjectsAndSubTopicsIndex;
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex
                        let qustionNumber = c.qustionNumber
                        let quizImageClassname = c.qustions[qustionNumber].image
                        let nextQustionNumber = (qustionNumber < c.qustions.length - 1) ? ++qustionNumber : qustionNumber

                        return (
                            <div className="paper Quiz test" key={uuidv4()}>
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Quiz</b>
                                </p>

                                <p>
                                    <b className='radio-qustion'>{c.documentSubTopic}</b>

                                </p>
                                <div className={`quiz-container ${quizImageClassname}`}>
                                    <Questionnaire />
                                    
                                </div>
                                <Button variant="contained" color="success" onClick={(e) => {
                                    // c.actions.setTestQustions('Networking', 'novice')
                                }}>Start<Clock /><FontAwesomeIcon icon={faPlay} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                                <Button variant="contained" color="error" onClick={(e) => {
                                    let timer = document.getElementById('timer').textContent
                                    c.actions.setTestDone(6, 'Networking-Quiz-Test', 'novice', timer) //id, Course, difficulty, took                                    
                                }}>Finish  <FontAwesomeIcon icon={faStop} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                                <Button variant="contained" onClick={(e) => {

                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = theSubjectsAndSubTopicsIndex
                                    // console.log(subjectsAndSubTopicsIndex);
                                    let completedIndex = nextsubTopicIndex;
                                    // console.log('completedIndex', completedIndex);
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    // console.log(subjectsAndSubTopicsCompletedIndex);
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    // console.log('obj', obj, obj.completed[subjectsAndSubTopicsCompletedIndex]);
                                    // console.log(obj);
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare

                                    let correctAnswerIndex
                                    let qustion
                                    correctAnswerIndex = c.qustions[nextsubTopicIndex].correctAnswerIndex
                                    // console.log('Quiz correctAnswerIndex', correctAnswerIndex);
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex, 'radio', nextQustionNumber, correctAnswerIndex)
                                    // console.log(c.qustions, c.subTopicIndex,nextsubTopicIndex);


                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
