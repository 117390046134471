import React, { Component } from "react";
import './topicviewersidebar.css';

import { Consumer } from './../../Context/'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareMinus, faFileLines, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



export default class TopicViewerSideBar extends Component {

    componentDidMount() {
        try {
            let element = document.querySelectorAll('li[aria-selected="true"]');
            let allChildren = element[0].querySelectorAll("div.MuiTreeItem-content");
            allChildren[0].click()
        } catch (e) {
            console.log(e);
        }

    }

    render() {

        return (

            <Consumer>

                {

                    c => {

                        // let quizSubjectAndTopics = c.subjectsAndSubTopics.filter((item, index) => item.section === 'quiz' && item.subIndex == c.choosenCourseSubIndex)
                        let docsSubjectAndTopics = c.subjectsAndSubTopics.filter((item, index) => item.section === 'documentation' && item.subIndex == c.choosenCourseSubIndex)
                        // let docsSubjectAndTopicsAndQuiz = [...docsSubjectAndTopics, ...quizSubjectAndTopics]

                        let subjects = docsSubjectAndTopics.map((item, index) => item.subject)
                        let subTopics = docsSubjectAndTopics.map((item, index) => item.subTopics)
                        // let defaultSelectedItems = `${subTopics[0][0]}0` // the first item in the menu

                        const initialValue = 0;
                        const finishedSubTopics = docsSubjectAndTopics.reduce((accumulator, currentValue) => accumulator + (currentValue.completed.filter(i => i != '').length), initialValue)
                        let defaultSelectedItems = c.itemIdArray[finishedSubTopics]

                        // finds the index of the completed 
                        let completedIndex = c.itemIdArray.map((item, index) => (item.includes(c.subTopic)) ? index : -1)
                        completedIndex = completedIndex.filter(item => item != -1)
                        // console.log(completedIndex);
                        // this code imetates the click on the curren menu
                        // element[0].click()
                        // element[0].click()

                        return (

                            <div className="topicviewersidebar-container">

                                <SimpleTreeView key={'topicviewersidebar-container-main-menu'}>

                                    <SimpleTreeView apiRef={this.props.innerRef} key={'SimpleTreeView-2'} defaultExpandedItems={subjects} defaultSelectedItems={[defaultSelectedItems]}>
                                        {docsSubjectAndTopics.map((item, index) => {

                                            return (

                                                <TreeItem key={item.subTopics + index} itemId={item.subject} label={item.subject} >
                                                    {
                                                        item.subTopics.map((i, index) => {

                                                            let itemId = `${i}${index}`
                                                            let icon;

                                                            if (item.completed[index] == '' && i.includes("Quiz"))
                                                                icon = faQuestion
                                                            else if (item.completed[index] == '' && !i.includes("Quiz"))
                                                                icon = faFileLines
                                                            else
                                                                icon = item.completed[index]

                                                            return (
                                                                <TreeItem key={`TreeItemsubKey-${i}`} itemId={itemId} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <FontAwesomeIcon icon={icon} style={{ fontSize: '13pt', color: '#7ac142' }} />
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{ fontWeight: 'inherit', marginLeft: '5px' }}>
                                                                        {i}
                                                                    </Typography>
                                                                </Box>
                                                                } onClick={(event) => {

                                                                    // here if the componentName ia a Quiz Component item
                                                                    // then the qustionType will effect the setDocumentSubTopic
                                                                    // this will propgate the qustions array in the index state
                                                                   
                                                                    // let qustionType
                                                                    // alert(c.componentName.name)
                                                                    
                                                                    // if (c.componentName.name == 'Quiz' || c.componentName == 'TestInstructionsNetworking')
                                                                    //     qustionType = 'radio'
                                                                    // else
                                                                    //     qustionType = 'terminal'
                                                                    
                                                                    // alert('The Qustion Type', qustionType)

                                                                    c.actions.setCompletedIndex(completedIndex)
                                                                    c.actions.setDocumentSubTopic(item, event.target.innerText);
                                                                }}>

                                                                </TreeItem>
                                                            )
                                                        })
                                                    }
                                                </TreeItem>

                                            )

                                        })}
                                    </SimpleTreeView>

                                </SimpleTreeView>

                            </div>

                        )
                    }
                }
            </Consumer >
        )
    }



}

